<template>
    <div class="bg-painel">
        <div class="bg-menu-header shadow border-bottom border-warning">
            <div class="container p-0">
                <div class="row justify-content-between">
                    <div class="col col-md-3">
                        <b-button v-b-toggle.sidebar-1 class="btn-menu bg-transparent border-0 text-dark fw-bold d-flex justify-content-between align-items-center p-4">
                            <b-icon icon="justify" scale="1"></b-icon>Menu
                        </b-button>
                    </div>
                    <div class="col col-md-3 d-flex justify-content-center align-items-center">
                            <img src="../../assets/images/encinterativa-logo.png" alt="" height="50">
                    </div>
                </div>
            </div>
            
        </div>
        <b-sidebar id="sidebar-1" title="Menu" shadow bg-variant="warning bg-gradient">
                <div class="p-3">
                    <nav class="mb-3 text-dark">
                        <b-nav vertical>
                            <b-nav-item  class='text-dark' @click="$router.push('/enc-painel/taxonomia')">Taxonomia</b-nav-item>
                            <b-nav-item  class='text-dark' @click="$router.push('/enc-painel/job')">Job</b-nav-item>
                            <b-nav-item  class='text-dark' @click="$router.push('/enc-painel/imagens')">Imagens</b-nav-item>
                        </b-nav>
                    </nav>
                </div>
        </b-sidebar>
        <div class="bg-container">
            <div class="container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LayoutInternas",
    created() {
        
        if (!localStorage.getItem("token_site")) {
            this.$router.push({name:'painel.login'})
        }
    }
}
</script>

<style scoped>

.bg-menu-header {
    background-color: #f9c8274a;
}

.bg-container {
    min-height: 100vh;
}

.btn-menu {
    min-width: 140px;
}

.btn-menu:hover{
    background-color: #f9c827b4 !important;
}

.btn{
    border-radius: 0px !important;
}

.bg-painel{
    background-color: #f9c82731;
}

.nav-link{
    color: black;
}

.nav-link:hover{
    background-color: white !important;
}
</style>
